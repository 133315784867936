import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const BackgroundImage = ({ bkgImg, partial, description }) => (
    <>
        <BackgroundContainer id="background-image" partial={partial}>
            <Img fluid={bkgImg} alt={description} />
        </BackgroundContainer>
    </>
)

export default BackgroundImage

const BackgroundContainer = styled.div`
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
    overflow: hidden;
    ${({ partial }) => css`
        height: ${partial && `70%`};
    `}
`
